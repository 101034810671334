
(function(){
    let menu = function(){
        $('body').toggleClass('noScroll');
    };
    
    let menuHover = function(){
        $('.mainMenu a').hover(
            function() {
                $(this).parent().addClass('active');
            }, function() {
                $(this).parent().removeClass('active');
            }
          );
    }

    let sldrHomeBanner = function(){
        $('.mdw-BannerSldr').slick({
            arrows: false,
            dots: true,
            infinite:false,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }

    let sliderLogos = function(){
        if ($('.mdw-logos').length > 0){
            $('.mdw-logos').slick({
                arrows: true,
                slidesToScroll: 1,
                infinite:false,
                responsive: [
                    {
                        breakpoint: 99999999,
                        settings: {
                            slidesToShow: 7,
                        }
                    },
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 6,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 4,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                        }
                    }
                ]
            })
        }
    }

    let sldrsTest = function(){
        let sldrHTest = $('.sdlrTest');

        sldrOpt = {
            dots: true,
            arrows: false,
            infinite: false,
            slidesToShow: 1,
            responsive: [{
                breakpoint: 99999,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            }]
        }

        sldrHTest.each((index,elem) =>{
            $(elem).slick(sldrOpt);
            $(window).on('resize', function () {
                if (!$(elem).hasClass('slick-initialized')) {
                    return $(elem).slick(sldrOpt);
                }
            });
        });
    }

    $('.menuMb .hamburger').on('click', menu);
   menuHover();
   sliderLogos();
   sldrHomeBanner();
   sldrsTest();
   
 })();